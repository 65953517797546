






































































































// Core
import {Component, Vue} from 'vue-property-decorator'
import {namespace} from 'vuex-class'


// Store
import {UsersActions} from '@store/users/types'

// Interfaces
import {User, UsersState} from '@store/users/interfaces'
import {ISelect} from '@/interfaces/Interface'
import {Meta, Paginate, RequestParams} from '@store/interfaces'
import Debounce from '@/decorators/Debounce'
import {Role} from '@/store/common/Interface'

const NSUsers = namespace('usersModule')

interface IAuth {
  login: string
  password: string
}

@Component({
  name: 'Users',

  components: {
    'v-user-card': () => import('./components/UserCard.vue'),
    'v-dialog-add-user': () => import('./components/DialogAddUser.vue'),
    'v-no-content': () => import('@/components/NoContent.vue'),
  },
})
export default class UsersComponents extends Vue {
  @NSUsers.State((state: UsersState) => state.users.data)
  private users!: User[]

  @NSUsers.State((state: UsersState) => state.users.meta)
  private meta!: Meta

  @NSUsers.State((state: UsersState) => state.roles)
  private roles!: Role[]

  @NSUsers.Action(UsersActions.A_GET_USERS) private fetchUsers!: (params?: RequestParams) => Promise<Paginate<User>>

  @NSUsers.Action(UsersActions.A_UPDATE_USER) private updateUser!: (user) => Promise<User>

  private visibleDialogAddUser: boolean = false
  private loading: boolean = false


  protected rules: any = {
    password: [{required: true, message: 'Пожалуйста введите пароль', trigger: 'blur'}],
    password_confirmation: [{required: true, message: 'Пожалуйста введите пароль', trigger: 'change'}],
  }

  private statusOptions: ISelect[] = [
    {
      label: 'Активен',
      value: '1',
    },
    {
      label: 'Не активен',
      value: '0',
    },
  ]

  private params: RequestParams = {
    search: '',
    isActive: '',
    roleId: '',
  }

  created() {
    this.changePage()
  }

  @Debounce(1000)
  private handleChangeSearch() {
    const search = this.params.search as string

    if (search.length >= 3 || search.length === 0) {
      this.changePage()
    }
  }

  private changeStatus() {
    this.changePage()
  }

  private changeRole() {
    this.changePage()
  }

  private async changePage(page = 1) {
    this.loading = true
    await this.fetchUsers({page, ...this.params})
    this.loading = false
    this.$scrollToTop()
  }

  private handleDeactivateUser(user: User) {
    this.loading = true
    this.updateUser({...user, is_active: false})
      .then(() => this.$successNotify())
      .catch(error => this.$errorNotify(error?.response?.data?.message))
      .finally(() => this.loading = false)
  }

  private handleActivateUser(user: User) {
    this.loading = true
    this.updateUser({...user, is_active: true})
      .then(() => this.$successNotify())
      .catch(error => this.$errorNotify(error?.response?.data?.message))
      .finally(() => this.loading = false)
  }


}
